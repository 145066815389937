/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'

// Elements
import { Hero } from '../components/shared'

const Content = styled(ParseContent)`
  & h2 {
    font-size: 22px;
    position: relative;
    margin-bottom: 2rem;
  }

  & h2::before {
    position: absolute !important;
    content: '';
    display: block;
    bottom: -10px;
    left: calc(50% - 100px);
    height: 3px;
    width: 200px;
    background: #cab996;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  & p {
    margin-bottom: 3rem;
  }
`

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        text {
          description
        }
      }

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout headerActive="Over ons">
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Hero backgroundImg={acf.banner.image} title={acf.banner.title} />

      <div className="container py-5">
        <Content content={acf.text.description} />
      </div>
    </Layout>
  )
}

export default PageTemplate
